import { getSupportedMimes } from "../../utils/mimes";
import { getEids } from "../../utils/eids";
import { JarvisResponse } from "../jarvis-service";
import { IBid, IProvider } from "../provider";
import { GeoService } from "../geo";
import { KeyValues } from "../keyvalues";

const GDPRCountryCodes = [ 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'GF', 'GP', 'MQ', 'ME', 'YT', 'RE', 'MF', 'GI', 'AX', 'PM', 'GL', 'BL', 'SX', 'AW', 'CW', 'WF', 'PF', 'NC', 'TF', 'AI', 'BM', 'IO', 'VG', 'KY', 'FK', 'MS', 'PN', 'SH', 'GS', 'TC', 'AD', 'LI', 'MC', 'SM', 'VA', 'JE', 'GG', 'GI', 'CH', ];
type PrebidAdUnit = { code: string, bids: any[] };
type RequestBidsOptions = { adUnitCodes?: string[], timeout?: number };
type RequestBidsReturn = Promise<{ bids: any, timedOut: boolean, auctionId: string }>;
type BuildVideoUrlOptions = { adUnit: PrebidAdUnit, params?: any, bid?: any };
type PrebidInstance = {
  cmd: Array<() => void>;
  bidderSettings?: any;
  adUnits: any[];

  requestBids(opts: RequestBidsOptions): RequestBidsReturn;
  processQueue(): void;
  setConfig(config: any): void;
  addAdUnits(adUnits: any[]): void;

  markWinningBidAsUsed(options: { adUnitCode: string }): void;
  getBidResponsesForAdUnitCode(code: string): { bids: any[] };

  adServers: {
    dfp: {
      buildVideoUrl(options: BuildVideoUrlOptions): string;
    }
  }
};

declare global {
  interface Window {
    [k: string]: PrebidInstance;
  }
}

export class PrebidProvider implements IProvider {
  public name = 'prebid';
  public globalName = 'pbjs';
  protected prevAdId = '';

  protected iu: string;
  protected kv = new KeyValues(() => document.location.href, window.localStorage, document.cookie);

  constructor() {
    this.iu = `/100151972/${window.location.hostname}`;
  }

  public async configure(config: JarvisResponse) {
    this.iu = `/100151972${config.mcm ? ',' + config.mcm : ''}/${window.location.hostname}`;
    const { bundleUrl, globalName, bids } = config?.videoConfig || {};

    if (window[globalName]) return;

    this.globalName = globalName;

    // Load bundle
    const script = document.createElement('script');
    script.async = true;
    script.src = bundleUrl;
    (document.head || document.body).appendChild(script);

    // Configure bidderSettings
    window[globalName] = window[globalName] || { cmd: [] };
    const pbjs = window[globalName];
    pbjs.cmd.push(() => {
      pbjs.bidderSettings = {
        deviceAccess: true,
        standard: {
          storageAllowed: true,
          adserverTargeting: [
            { key: "hb_cpm", val: (resp: any) => resp.cpm.toFixed(2) },
            { key: "hb_bidder", val: (resp: any) => resp.bidderCode },
            { key: 'hb_adomain', val: (resp: any) => resp.meta?.advertiserDomains && resp.meta.advertiserDomains[0] }
          ],
        },
      }
    });

    // Prebid set config
    const consentManagement: any = {};

    consentManagement.usp = {
      cmpApi: 'static',
      consentData: { 
        getUSPData: { uspString: '1---' },
      },
    };

    const geo = await GeoService.getInstance().getGeo();
    
    if (geo) {
      if (GDPRCountryCodes.includes(geo.country_code) && window.__tcfapi) {
        consentManagement.gdpr = { cmpApi: 'iab' };
      } else if (geo.country_code === 'US' && geo.region_code === 'CA' && window.__uspapi) {
        consentManagement.usp = { cmpApi: 'iab' };
      }
    }

    const mcmEnabled = !!config.mcm;
    const jarvisUserIds = config.videoConfig.userIds.map((u) => {
      if (['criteo', 'lotamePanoramaId'].includes(u.name)) return { name: u.name };
      return u;
    });;
    const defaultUserIds = [
      { name: 'lotamePanoramaId' },
      { name: 'sharedId', storage: { type: 'cookie', name: '_pubcid', expires: 365 } },
      { name: 'uid2' },
      { name: 'idx' },
      { name: 'criteo' },
      { name: 'pubProvidedId', params: { eidsFunction: getEids } },
    ];

    const userIds = jarvisUserIds.length > 0 ? jarvisUserIds : defaultUserIds;
    const prebidConfig: any = {
      enableTIDs: true,
      consentManagement,
      firstPartyData: { enabled: true, skipEnrichments: false },
      gptPreAuction: { enabled: true, mcmEnabled },
      pageUrl: location.href,
      pubcid: { expInterval: 525600 },
      bidderTimeout: 2000,
      enableSendAllBids: false,
      priceGranularity: {
        buckets: [
          { precision: 2, max: 50, increment: 0.01 },
        ],
      },
      useBidCache: true,
      cache: { url: "https://prebid.adnxs.com/pbc/v1/cache", batchSize: 5, batchTimeout: 200 },
      userSync: {
        iframeEnabled: true,
        syncEnabled: true,
        filterSettings: { all: { bidders: '*', filter: 'include' } },
        syncDelay: 100,
        auctionDelay: 500,
        syncsPerBidder: 50,
        userIds, 
      },
      ortb2: {
        user: {
          geo: geo ? {
            type: 2,
            ipservice: 1,
            lat: geo.latitude,
            lon: geo.longitude,
            country: geo.country_code,
            region: geo.region_code,
            city: geo.city,
            zip: geo.zip_code,
          } : null,
        }
      }
    };
    if (config.schain) {
      prebidConfig.schain = { validation: 'relaxed', config: { ver: "1.0", complete: 1, nodes: [{ asi: "arkadium.com", sid: config.schain, hp: 1 }] } };
    }
    pbjs.cmd.push(() => {
      pbjs.setConfig(prebidConfig);
    });

    pbjs.cmd.push(() => {
      pbjs.addAdUnits([
        this.createAdUnit('skippable', bids, { skip: 1 }),
        this.createAdUnit('unskippable', bids, { skip: 0 }),
      ]);
    });
  }

  public async requestBids(params: any): Promise<IBid[]> {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => resolve([]), 4000);
      const pbjs = window[this.globalName];
      pbjs.cmd.push(() => {
        pbjs.requestBids({ adUnitCodes: ['skippable', 'unskippable'] })
          .then(({ bids }) => {
            const { skippable, unskippable } = bids || {};
            const allBids = [].concat(skippable?.bids, unskippable?.bids)
              .filter((bid: IBid['bid']) => !!bid)
              .map(bid => ({ bid, provider: this }));
            resolve(allBids);
          })
          .catch((err) => {
            console.error(err);
            resolve([]);
          });
      });
    });
  }

  public buildVideoUrl(bid: IBid, noGoogle = false) {
    if (noGoogle) return this.buildSimpleUrl(bid);
    return new Promise((resolve, reject) => {
      setTimeout(() => reject('buildVideoUrl timeout.'), 1000);
      const { adUnitCode, adId } = bid.bid!;
      const pbjs = window[this.globalName];
      pbjs.cmd.push(() => {
        const adUnit = pbjs.adUnits.find(u => u.code === adUnitCode);
        const videoUrl = pbjs.adServers.dfp.buildVideoUrl({
          adUnit,
          params: {
            iu: this.iu,
            sz: '1x1|640x360|640x390|640x480',
            ppid: this.kv.getPPID(),
            cust_params: this.kv.getObject({ retryOnError: '0' })
          }
        });
        pbjs.markWinningBidAsUsed({ adId } as any);
        resolve(videoUrl);
      });
    }) as Promise<string>;
  }

  protected async buildSimpleUrl(bid: IBid) {
    return bid.bid.vastUrl || 'data:application/xml,' + encodeURIComponent(bid.bid.vastXml || '');
  }

  protected createAdUnit(code: string, bids: any[], { skip }: { skip: 1 | 0 }) {
    const placement = 1;
    const plcmt = 1;
    const pos = 1;
    const { hostname } = window.location;
    const defaultSize = [640, 480];
    return {
      code,
      bids,
      sizes: [[1,1], [640,360], [640,390], defaultSize],
      ortb2Imp: {
        ext: {
          gpid: `/100151972/${hostname}/ark_pre-roll`,
          data: { pbadslot: `/100151972/${hostname}/ark_pre-roll` }
        }
      },
      mediaTypes: {
        video: {
          context: 'instream',
          skip,
          placement,
          pos,
          plcmt,
          startdelay: 0,
          minduration: 1,
          maxduration: 32,
          w: defaultSize[0],
          h: defaultSize[1],
          playerSize: defaultSize,
          linearity: 1,
          delivery: [1, 2],
          api: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          protocols: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
          playbackmethod: [3],
          playbackend: 1,
          mimes: ["application/javascript", "video/mp4"].concat(getSupportedMimes()),
        },
      },
    };
  }
}
